const FloorPlanData = [
    {
        id: 1,
        cover1: require("../../Assets/FloorPlan/office-floor-plans-tower-A-scaled.jpg"),
        alttag1: "One FNG Noida",
        cover2: require("../../Assets/FloorPlan/office-floor-plans-tower-B-scaled.jpg"),
        alttag2: "One FNG",
    },
    // {
    //     id:2,
    //     cover1: require("../../Assets/FloorPlan/floor5-1.jpg"),
    //     alttag1:"One FNG Expressway",
    //     cover2: require("../../Assets/FloorPlan/floor5-2.jpg"),
    //     alttag2:"One FNG Group 108",
    // },
    // {
    //     id:3,
    //     cover1: require("../../Assets/FloorPlan/floor1-1.jpg"),
    //     alttag1:"eye of noida",
    //     cover2: require("../../Assets/FloorPlan/floor1-2.jpg"),
    //     alttag2:"eon eye of noida",
    // },
    // {
    //     id:4,
    //     cover1: require("../../Assets/FloorPlan/floor2-1.jpg"),
    //     alttag1:"fairfox noida",
    //     cover2: require("../../Assets/FloorPlan/floor2-2.jpg"),
    //     alttag2:"eon project noida",
    // },
    // {
    //     id:5,
    //     cover1: require("../../Assets/FloorPlan/floor3-1.jpg"),
    //     alttag1:"eon sector 140 noida",
    //     cover2: require("../../Assets/FloorPlan/floor3-2.jpg"),
    //     alttag2:"eon noida sector 140",
    // },
]

export default FloorPlanData;